// TrackDetails.js
import React, { useState, useEffect } from "react";
import LyricsBox from "./LyricsBox";
import LyricsUI from "./LyricsUI";

function TrackDetails({ track, player }) {
  const [requestedLyrics, setRequestedLyrics] = useState({});
  const [numberAndDelay, setNumberAndDelay] = useState([0, 0]);

  // request orginal lrc lyric.
  useEffect(() => {
    if (!track) return;
    const key = track.artist + track.name;
    setRequestedLyrics((rq) => {
      if (!rq[key]) {
        fetch(
          'http://192.168.0.101:3500/findLyrics?aname=' + track.artist + '&sname=' + track.name
        )
          .then((response) => response.json())
          .then((data) => {
            setRequestedLyrics((rq) => ({
              ...rq,
              [key]: [[track.lyric, track.delay], ...data.map((lyric) => ([lyric.lyrics, 0]))],
            }));
          })
          .catch((error) => console.error("Error fetching lyrics:", error));
      }
      setNumberAndDelay([0, track.delay]);
      return rq;  // 返回当前状态
    });
  }, [track]);  // 仅在 track 变化时触发

  if (!track) return null;
  const key = track.artist + track.name;
  if (!requestedLyrics[key]) return null;
  if (numberAndDelay[0] === 0 && !track.lyric && requestedLyrics[key].length > 1)
    setNumberAndDelay([1, 0]);

  return (
    <div className="track-details">
      <LyricsBox lrcText={requestedLyrics[key][numberAndDelay[0]][0] || "[00:00.00] Lyric is Empty!"}
        player={player}
        delay={numberAndDelay[1]}></LyricsBox>
      <LyricsUI numberAndDelay={numberAndDelay}
        lrcText={requestedLyrics[key][numberAndDelay[0]][0] || "[00:00.00] Lyric is Empty!"}
        totalNumber={requestedLyrics[key].length}
        trackid={track.trackid}
        setNumberAndDelay={setNumberAndDelay}></LyricsUI>
    </div >
  );
}

export default TrackDetails;
