import React, { useRef, useEffect, useState } from 'react'

const LyricsBox = ({ lrcText, player, delay }) => {
    const [lIndex, setLlIndex] = useState(-1);
    const lines = lrcText.split("\n");
    const [lastScrollTime, setLastScrollTime] = useState(0);
    const div = useRef(null);

    const lyrics = lines
        .map((line) => {
            const match = line.match(/\[(\d{2}):(\d{2})\.(\d{2})\d?\]?(.*)/);
            if (match) {
                const minutes = parseInt(match[1], 10);
                const seconds = parseInt(match[2], 10);
                const milliseconds = parseInt(match[3], 10);
                const text = match[4];
                const tim = minutes * 60 + seconds + milliseconds / 1000 + delay;
                return { tim, text };
            }
            return null;
        })
        .filter((line) => line !== null);

    // 更新当前歌词索引
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (player && !player.ended && lyrics.length > 0) {
                const currentTime = player.currentTime;
                const newIndex = lyrics.findIndex((lyric, index) => {
                    return (
                        currentTime >= lyric.tim &&
                        (index === lyrics.length - 1 ||
                            currentTime < lyrics[index + 1].tim)
                    );
                });
                if (div.current && new Date().getTime() - lastScrollTime >= 5000) {
                    const allNodes = div.current.querySelectorAll("p[index]");
                    if (newIndex < allNodes.length) {
                        if (newIndex === -1) div.current.scrollTop = 0;
                        else div.current.scrollTop =
                            allNodes[newIndex].offsetTop -
                            div.current.offsetHeight / 2.3;
                    }
                }
                if (newIndex !== lIndex) {
                    setLlIndex(newIndex);
                }
            }
        }, 100); // 每 0.1 秒更新一次

        return () => clearInterval(intervalId);
    }, [player, lyrics, lIndex, lastScrollTime]);

    return (
        <div className="lyrics"
            onScroll={(e) => {
                const now = new Date().getTime();
                if (now - lastScrollTime >= 1000) setLastScrollTime(now);
            }}
            ref={div}>
            {lyrics.map((lyric, index) => (
                <p
                    onClick={(e) => {
                        player.currentTime = parseFloat(e.target.getAttribute("tim"));
                        player.play();
                    }}
                    tim={lyric.tim}
                    key={index}
                    index={index}
                    className={
                        (index <= lIndex ? "highlight" : "") +
                        " " +
                        (!index ? "margin-top-15" : "") +
                        " " +
                        (index === lyrics.length - 1 ? "margin-bottom-15" : "")
                    }
                >
                    {lyric.text}
                </p>
            ))}
        </div>
    )
}

export default LyricsBox