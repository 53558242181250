import React, { useState, useEffect } from "react";
import PlayerControls from "./components/Controller/PlayerControls";
import Playlist from "./components/AlbumPage/Playlist";
import TrackDetails from "./components/LyricsPage/TrackDetails";
import PlyrAudio from "./components/Controller/PlyrAudio";
import "./styles.css";
import "./controller.css";
import "./icons.css";
import SearchBar from "./components/Search/SearchBar";

function App() {
  const [tracks, setTracks] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [filteredAlbums, setFilteredAlbums] = useState([]);
  const [player, setPlayer] = useState(null);
  const [localTracks, setLocalTracks] = useState({})
  const [pageFlip, setPageFlip] = useState(false)
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [paraInfos, setParaInfos] = useState({ mode: 'client', user: 'cyy' });

  // getInfo
  useEffect(() => {
    fetch('http://192.168.0.101:3500/localAlbums')
      .then((response) => response.json())
      .then((data) => {
        setAlbums(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
    fetch('http://192.168.0.101:3500/localTracks')
      .then((response) => response.json())
      .then((data) => {
        const result = {}
        for (const track of data) {
          if (!result[track.albumid])
            result[track.albumid] = []
          result[track.albumid].push(track)
        }
        setLocalTracks(result);
      })

    // const url = window.location.search.split('?');
    // if (url.length > 1) {
    //   const paraArr = url[url.length - 1].split('&');
    //   const obj = { ...paraInfos };
    //   for (const item of paraArr) {
    //     const tmp_kv = item.split('=');
    //     obj[tmp_kv[0]] = tmp_kv[1];
    //   }
    //   setParaInfos(obj);
    // }

    // if (obj.mode === 'client') {
    //   const ws = new WebSocket('ws://localhost:3700');
    //   setSocket(ws);
    //   ws.onmessage = (event) => {
    //     const command = event.data;
    //     if (command === 'play') {
    //       player.play();
    //     } else if (command === 'pause') {
    //       player.pause();
    //     } else if (command === 'next') {
    //     } else if (command === 'prev') {
    //     }
    //   };

    //   return () => ws.close();
    // }
  }, []);

  useEffect(() => {
    if (!player) return;
    player.source = {
      type: "audio",
      sources: [
        {
          src: tracks[currentTrackIndex].url
        },
      ],
    };
    console.log('http://192.168.0.101:3600/' + tracks[currentTrackIndex].url);
  }, [currentTrackIndex, tracks, player]);

  return (
    <div className="App">
      <PlayerControls
        player={player}
        tracks={tracks}
        pageFlip={pageFlip}
        setPageFlip={setPageFlip}
        currentTrackIndex={currentTrackIndex}
        setCurrentTrackIndex={setCurrentTrackIndex}
      >
        <PlyrAudio setPlayer={setPlayer} />
      </PlayerControls>


      <div style={{ 'display': pageFlip ? 'unset' : 'none' }}>
        <TrackDetails
          track={tracks[currentTrackIndex]}
          player={player}
          currentTrackIndex={currentTrackIndex}
        /></div>
      <div style={{ 'display': pageFlip ? 'none' : 'unset' }}>
        <SearchBar albums={albums}
          setFilteredAlbums={setFilteredAlbums} />
        <Playlist
          albums={filteredAlbums}
          tracks={tracks}
          localTracks={localTracks}
          setTracks={setTracks}
          setCurrentTrackIndex={setCurrentTrackIndex}
        /></div>
    </div>
  );
}

export default App;
